// EBEController.js

import React, { useState, createContext, useContext } from "react";
import { useModel } from "../Model/ModelProvider";

export const EBEContext = createContext();

export const useEBE = () => {
    const context = useContext(EBEContext);
    if (!context) {
        throw new Error("useEBE must be used within an EBE Provider");
    }
    return context;
};

const EBEController = ({ children }) => {
    const { getEBEs, setIsChanged, updateModel } = useModel();
    const ebeList = getEBEs();

    const [selectedEBE, setSelectedEBE] = useState(null);
    const [partialName, setPartialName] = useState("");

    const commitChanges = (newEBELIist) => {
        updateModel(
            "UPDATE_MODEL_PART",
            { partKey: "ebes", value: newEBELIist },
            () => {
                setIsChanged(true); // 
            }
        );
    };

    const saveEBE = (ebe) => {
        const updatedEBEs = ebeList.some(item => item.id === ebe.id)
            ? ebeList.map(item => (item.id === ebe.id ? ebe : item))
            : [...ebeList, ebe];
        commitChanges(updatedEBEs);
        setSelectedEBE(null);
    };

    const removeEBE = (ebe) => {  // Remove EBE from list
        alert('Any Generator relationships will also be deleted')
        updateModel(
            "DELETE_EBE",
            { value: ebe.id },
            () => {
                setIsChanged(true); // 
            }
        );
        setPartialName("");
        setSelectedEBE(null);
    }
    return (
        <EBEContext.Provider value={{ 
            ebeList, 
            selectedEBE, 
            setSelectedEBE, 
            saveEBE, 
            removeEBE, 
            partialName, 
            setPartialName 
        }}>
            {children}
        </EBEContext.Provider>
    );
};

export default EBEController;
