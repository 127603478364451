import { useState, useEffect } from "react";
import { useEBE } from "./EBEController";
import { BsTrash } from "react-icons/bs";

// Styles
import "../../css/Form.css";

const initialState = { id: "", name: "", description: "", isUOW: false };

const EBEForm = () => {
  const { selectedEBE, setSelectedEBE, saveEBE, removeEBE, partialName, setPartialName } = useEBE();

  const [ebeData, setEBEData] = useState(selectedEBE || initialState);

  useEffect(() => {
    setEBEData(selectedEBE || initialState);
  }, [selectedEBE]);

  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setEBEData((values) => ({ ...values, [name]: value }));
    setActive(true);
  };

  const handleNameChange = (event) => {
    const trimmedText = event.target.value.trim();
    setPartialName(trimmedText);
    handleChange(event);
  };

  const handleKeyDown = (event) => {
    const trimmedText = event.target.value.trim();
    if ((event.key === "Enter" || event.key === "Tab") && trimmedText) {
      event.preventDefault();
      setPartialName("");
      // Step to the next field.
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const id = ebeData.name.replace(/\s+/g, ""); // strip embedded spaces
    const ebe = { ...ebeData, id }; // replace id in ebeData, isn't strictly necessary as name chnge is disabled but...
    saveEBE(ebe);
  };

  const handleReset = () => {
    // back out current changes
    setEBEData(selectedEBE || initialState);
    setActive(false);
    setPartialName("");
  };

  const handleCancel = () => {
    // clear the form and selected ebe
    setEBEData(initialState);
    setActive(false);
    setPartialName(""); // should be able to factor out this repetition
    setSelectedEBE(null);
  };

  const onDelete = () => {
    removeEBE(ebeData);
    setActive(false);
  };

  return (
    <div>
      <form>
        <fieldset>
          {" "}
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={ebeData.name || ""}
              placeholder="Search/New entity..."
              onChange={handleNameChange}
              pattern="[ a-zA-Z0-9_\-]+"
              onKeyDown={handleKeyDown}
              disabled={ebeData.id}
            />
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={ebeData.description}
              onChange={handleChange}
              disabled={!ebeData.name}
            />
          </label>
          <label>
            UoW:
            <input
              type="checkbox"
              style={{ margin: "5px" }}
              name="isUOW"
              checked={ebeData.isUOW || false}
              onChange={handleChange}
              disabled={!ebeData.name}
            />
          </label>
          <br />
          <div className="button-container">
            <button
              name="submitBtn"
              type="button"
              className="csbutton"
              disabled={!ebeData.name || !active || partialName}
              onClick={handleSubmit}
            >
              Save
            </button>
            <div className="right-buttons">
              <button
                name="restBtn"
                type="button"
                className="csbutton"
                disabled={!ebeData.name || !active}
                onClick={handleReset}
              >
                Reset
              </button>
              <button
                name="clearBtn"
                type="button"
                className="csbutton"
                disabled={!ebeData.name}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <span
                className="trashCanButton"
                title="Delete this EBE!"
                style={{
                  visibility: !ebeData.id ? "hidden" : "visible",
                }}
                onClick={onDelete}
              >
                <BsTrash
                  className="cs-icon"
                ></BsTrash>
              </span>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default EBEForm;
