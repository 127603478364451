import React, { useContext } from "react";
import { BsTrash } from "react-icons/bs";

import { GeneratorContext } from "./GeneratorLogic";
import { useModel } from "../Model/ModelProvider";

const GeneratorListItem = ({ genSource }) => {
  const { generatorList } = useContext(GeneratorContext);
  const { getEBEById } = useModel();

  //  console.log('GeneratorListItem', genSource);
  const gens = generatorList.filter((g) => g.source === genSource);
  const sourceEBE = getEBEById(genSource);
  const renderedSublistItems = gens.map((g) => {
    return <GeneratorSublistItem key={g.id} {...g} />;
  });

  return (
    <div key={genSource}>
      <li key={"li" + genSource} id={genSource} className="js-model-list-item">
        {sourceEBE ? sourceEBE.name : genSource}
      </li>
      <ul
        className="js-model-list"
        key={"ul_" + genSource}
        id={"ul_" + genSource}
      >
        {renderedSublistItems}
      </ul>
    </div>
  );
};

export default GeneratorListItem;

const GeneratorSublistItem = (targetGen) => {
  const { id, target, description, processType } = targetGen;

  const { selectedGenerator, setSelectedGenerator, removeGenerator } =
    useContext(GeneratorContext);
  const { getEBEById } = useModel();
  const selectedID = selectedGenerator ? selectedGenerator.id : null;

  const targetEBE = getEBEById(target);

  const setCurrentGenerator = () => {
    selectedID === id
      ? setSelectedGenerator(null)
      : setSelectedGenerator(targetGen);
  };

  const onDelete = () => {
    removeGenerator(targetGen);
    setSelectedGenerator(null);
  };

  return (
    <li
      id={id}
      onClick={setCurrentGenerator}
      className={`js-model-list-item sublist ${
        selectedID === id ? "checked" : ""
      }`}
    >
      {/* Main Content */}
      <div className="content">
        <em>{description}</em>
        <strong>{targetEBE.name}</strong>
        <em className="process-type">[{processType}]</em>
      </div>

      {/* Action Buttons */}
      <div className="right-button-container">
        <span
          className="remove actionButton"
          title="Delete this Generator!"
          style={{
            display:
              selectedGenerator && selectedGenerator.id === id
                ? "inline"
                : "none",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent click bubbling to li
            onDelete();
          }}
        >
          <BsTrash className="cs-icon" />
        </span>
      </div>
    </li>
  );
};
