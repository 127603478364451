import { useEffect, useState } from 'react'
import { useModel } from '../Model/ModelProvider';

import FirstCut from './FirstCut/FirstCut';
import SecondCut from './SecondCut/SecondCut';
import CollapsibleComponent from "../UI/CollapsibleComponent";
import Tabs from "../UI/Tabs";
import { useFullScreen } from '../UI/FullScreenProvider';
import FullScreenButton from '../UI/FullScreenButton';

// styles
import '../../css/PAD.css'
import '../../css/Tab.css'
import '../../css/ContextMenu.css'

const PADHandler = () => {
    const { getPAD2, replacePADEditableSchema } = useModel();
    const { replaced, modified } = getPAD2();
    const [activeTab, setActiveTab] = useState('pad1');
    const { fullScreen, toggleScreenMode } = useFullScreen();

    useEffect(() => {
        // Whenever the second cut schema is replaced, stealth replace the editable schema
        // This is to avoid the PAD2SecondCut components trying to find things that aren't there anymore
        if (replaced && !modified) {
            console.log("PADHandler: silent replace editableSchema", 'pad2');
            replacePADEditableSchema('pad2');
        }
    }, [replaced, modified, replacePADEditableSchema]);

    const tabData = [
        { key: 'pad1', label: 'First Cut', component: <FirstCut isActive={activeTab === 'pad1'} /> },
        { key: 'pad2', label: 'Second Cut', component: <SecondCut isActive={activeTab === 'pad2'} /> },
    ];

    const options = {
        component: < FullScreenButton fullScreen={fullScreen}
            toggleScreenMode={toggleScreenMode} element_id={activeTab} />
    }

    return (
        <CollapsibleComponent label="Process Architecture">
            <Tabs tabs={tabData} activeTab={activeTab} setActiveTab={setActiveTab}
                tabOptions={options} >
                {tabData.map(tab => (
                    <div key={tab.key} hidden={activeTab !== tab.key}>
                        {tab.component}
                    </div>
                ))}
            </Tabs>
        </CollapsibleComponent >
    )
}

export default PADHandler