import { useState, createContext, useEffect } from 'react'
import { useModel } from '../../Model/ModelProvider';

export const SecondCutContext = createContext()

const SecondCutLogic = ({ children }) => {
    const { getPAD1, getPAD2 } = useModel();
    const { schema, replaced, editableSchema, modified } = getPAD2();
    const { replacePADSchema, replacePADEditableSchema, updatePADEditableSchema } = useModel();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const [graphId, setGraphId] = useState('')

    useEffect(() => {
        // Whenever the first cut editable schema changes, overwrite the second cut schema
        // with the first cut editable schema
        const pad1  = getPAD1();
        if (schema && JSON.stringify(pad1.editableSchema) === JSON.stringify(schema)) return;
        const newSchema = [...pad1.editableSchema]
        console.log("PAD2SecondCut use Effect: REPLACE_PAD_SCHEMA");
        replacePADSchema('pad2', newSchema);
    }, [getPAD1, replacePADSchema, schema]);

    useEffect(() => {
        // Whenever the second cut schema is replaced, check if it is different from the editable schema
        if (replaced && modified) {
            console.log("PAD2SecondCut: clobber editableSchema");
            setIsModalOpen(true); // Open the modal when schemas differ
        } else if (replaced) {
            console.log("PAD2SecondCut: silent replace editableSchema", 'pad2', replaced, modified);
            replacePADEditableSchema('pad2');
        }
    }, [replaced, modified, replacePADEditableSchema]);

    const [selectedNodeType, setSelectedNodeType] = useState(null)

    useEffect(() => {
        console.log('PAD2Diagram: graphId', graphId)
        const selectedType = editableSchema.find(s => s.id === graphId)?.type;
        setSelectedNodeType(selectedType);
    }, [graphId, editableSchema])


    // PAD Second-Cut functions
    const compare = (a, b) => {
        if (a.id < b.id) { return -1 }
        if (a.id > b.id) { return 1 }
        return 0
    }

    const mergeSchema = () => {
        // Merge the pad2 schema into the editableSchema.
        // If all that has changed is the label
        // then we can just merge the label changes.
        // 
        const edits = [...editableSchema].sort(compare)

        const updatedE = edits.map(e => {
            const s = schema.find(s => s.id === e.id);
            if (s) {
                return { ...e, label: s.label };
            }
            return e;
        });

        // Add objects from a to b if not already in b
        schema.forEach(s => {
            if (!updatedE.some(e => e.id === s.id)) {
                updatedE.push({ ...s });
            }
        });

        // Remove objects from b if not present in a
        const mergedArray = updatedE.filter(e => schema.some(s => s.id === e.id));

        return mergedArray;
    }

    const handleReplace = () => {
        console.log("Overwrite Second-cut changes ");
        replacePADEditableSchema('pad2');
        setIsModalOpen(false); // Close the modal
    };

    const handleMerge = () => {
        console.log("PAD2 Merge requested");
        const newSchema = mergeSchema();
        // Update the editableSchema with the merged schema
        updatePADEditableSchema('pad2', newSchema, true);
        setIsModalOpen(false); // Close the modal
    };

    const restore = () => {
        console.log("Force restore editableSchema back to schema ");
        replacePADEditableSchema('pad2', true);
    };

    return (
        <SecondCutContext.Provider value={{ graphId, setGraphId, restore, isModalOpen, selectedNodeType,
         setSelectedNodeType, handleReplace, handleMerge }}>
            {children}
        </SecondCutContext.Provider>
    );
}

export default SecondCutLogic