import FirstCutLogic from './FirstCutLogic';
import FirstCutUI from './FirstCutUI';

const FirstCut = ({ isActive }) => {
    return (
        <FirstCutLogic>
            <FirstCutUI isActive={isActive} />
        </FirstCutLogic>
    );
};

export default FirstCut;