const ContextMenu = ({ position, isShowContext, menuItems }) => {
  if (!isShowContext) return null;

  return (
    <div className="context-menu" style={{ top: position.y, left: position.x }}>
      <ul className="menu">
        {menuItems.map((item, index) => (
          <li key={index} className="menu__item" onClick={item.onClick}>
            {item.icon && <item.icon className="menu__icon" />}
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContextMenu;
