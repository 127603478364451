import React, { useContext, useRef, useState, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";
import { useOutsideEvent } from "../hooks/useOutsideEvent";
import { ReactComponent as Palette } from "../../icons/Palette.svg";

//styles
import "../../css/Themes.css";

const ThemePaletteSelector = () => {
  const paletteRef = useRef(null);
  const [dropdown, setDropdown] = useOutsideEvent(paletteRef, false);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const { theme, setTheme } = useContext(ThemeContext);
  const [themeName, setThemeName] = useState("");

  const getCssVariableValue = (variableName) => {
    return getComputedStyle(document.documentElement).getPropertyValue(
      variableName
    );
  };

  useEffect(() => {
    setTimeout(() => {
      // Force redraw to happen last so theme change has time to take effect
      setThemeName(getCssVariableValue("--theme-name"));
    }, 0);
  }, [theme]);

  return (
    <div className="menu-item" ref={paletteRef}>
      <button onClick={toggleDropdown} aria-label="Select a colour palette">
        {<Palette className="palette-icon" />}
      </button>
      <ul className={`dropdown dropdown-submenu ${dropdown ? "show" : ""}`}>
        <li className="menu-item">
          <header>Current theme: {themeName} </header>
          <hr />
        </li>
        <li className="menu-item">
          <button value="light" onClick={(e) => setTheme(e.target.value)}>
            Light (Default)
          </button>
        </li>
        <li className="menu-item">
          <button value="dark" onClick={(e) => setTheme(e.target.value)}>
            Dark
          </button>
        </li>
        <li className="menu-item">
          <button
            value="dark-night-vision-red"
            onClick={(e) => setTheme(e.target.value)}
          >
            Night Vision (Red)
          </button>
        </li>
        <li className="menu-item">
          <button
            value="dark-night-vision-green"
            onClick={(e) => setTheme(e.target.value)}
          >
            Night Vision (Green)
          </button>
        </li>
        {/* <li className="menu-item">
                    <button value="dark-monochrome-green" onClick={(e) => setTheme(e.target.value)}>Monochrome (Green-Dark)</button>
                </li>
                <li className="menu-item">
                    <button value="light-muted" onClick={(e) => setTheme(e.target.value)}>Muted (Light)</button>
                </li>
                <li className="menu-item">
                    <button value="dark-muted" onClick={(e) => setTheme(e.target.value)}>Muted (Dark)</button>
                </li>
                <li className="menu-item">
                    <button value="red-contrast" onClick={(e) => setTheme(e.target.value)}>Contrast (Red)</button>
                </li>
                <li className="menu-item">
                    <button value="blue-contrast" onClick={(e) => setTheme(e.target.value)}>Contrast (Blue)</button>
                </li>
                <li className="menu-item">
                    <button value="yellow-contrast" onClick={(e) => setTheme(e.target.value)}>Contrast (Yellow)</button>
                </li>
                <li className="menu-item">
                    <button value="bright-red" onClick={(e) => setTheme(e.target.value)}>Bright (Red)</button>
                </li>
                <li className="menu-item">
                    <button value="bright-blue" onClick={(e) => setTheme(e.target.value)}>Bright (Blue)</button>
                </li>
                <li className="menu-item">
                    <button value="bright-yellow" onClick={(e) => setTheme(e.target.value)}>Bright (Yellow)</button>
                </li> */}
      </ul>
    </div>
  );
};

export default ThemePaletteSelector;
