
const Pane = ({ title, children }) => {
    return (
        <div className="pane">
            {title && <div className="pane-title-bar">{title}</div>}
            <div className="pane-content">{children}</div>
        </div>
    );
};

export default Pane;