
import { FaExpand, FaCompress } from 'react-icons/fa';
const FullScreenButton = ({ fullScreen, toggleScreenMode, element_id }) => {
    return (
        <ul className="button-container">
            <li className="menu-item">
                <button 
                    onClick={() => toggleScreenMode(document.getElementById(element_id))}
                >
                    {fullScreen ? <FaCompress className='menu-symbol' /> : <FaExpand className='menu-symbol' />}
                </button>
            </li>
        </ul>
    );
};

export default FullScreenButton;