// src/features/AppFooter/AppFooter.js
import React from "react";
import Bar from "./Bar";

// Custom hook to handle external links
function useExternalLink(url) {
  return () => window.open(url, "_blank", "noopener,noreferrer");
}

function AppFooter() {
  const openGGSeal = useExternalLink("https://my.greengeeks.com/seal/");

  const rightContent = (
    <button
      onClick={openGGSeal}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
      }}
    >
      <img
        src="https://static.greengeeks.com/ggseal/Green_4.png"
        alt="GreenGeeks Seal"
        style={{ width: "50%", height: "auto" }}
      />
    </button>
  );

  const centreContent = (
    <>
      <img src="/SunIcon.png" alt="Company Logo" className="bar-text-image" />
      <div className="centre-text">Composed Software Ltd.</div>
      <img src="/MoonIcon.png" alt="Company Logo" className="bar-text-image" />
    </>
  );

  const leftContent = null;

  return (
    <div className="footer">
      <Bar
        leftContent={leftContent}
        centreContent={centreContent}
        rightContent={rightContent}
      />
    </div>
  );
}

export default AppFooter;
