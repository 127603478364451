import React, { createContext, useContext, useState } from 'react';

import toggleFullScreen from './ScreenMode';

const FullScreenContext = createContext();

export const FullScreenProvider = ({ children }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const toggleScreenMode = (element) => {
        toggleFullScreen(element);
        setFullScreen(!fullScreen);
    };

    return (
        <FullScreenContext.Provider value={{ fullScreen, toggleScreenMode }}>
            {children}
        </FullScreenContext.Provider>
    );
};

export const useFullScreen = () => {
    return useContext(FullScreenContext);
};