import React, { useRef } from "react";
import PAD2DiagramLogic from "./PAD2DiagramLogic";
import PAD2EditorItem from "./PAD2EditorItem";
import { useModel } from "../../Model/ModelProvider";
import { usePAD2 } from "./PAD2DiagramLogic";
import Bar from "../../../Bar";

//import { BsHexagon } from "react-icons/bs";
//import { FiPlus } from 'react-icons/fi';
import { MdMergeType } from "react-icons/md";
//import { FaPlus } from 'react-icons/fa';

const PAD2Editor = () => {
  return (
    <PAD2DiagramLogic>
      <PAD2EditorUI />
    </PAD2DiagramLogic>
  );
};

const PAD2EditorUI = () => {
  const { getPAD2 } = useModel();
  const { editableSchema } = getPAD2();
  const { foldAllTaskCMPs } = usePAD2();

  const renderedListItems = editableSchema
    .filter((s) => s.class === "node" && s.status === "live")
    .map((item) => {
      return (
        <PAD2EditorItem
          // add a stringify to the key to avoid duplicate key error
          key={JSON.stringify(item)}
          process={item}
        />
      );
    });

  const listRef = useRef(null);

  const leftContent = (
    <div className="button-container">
      <span
        className="edit actionButton"
        title="Fold all task CMPs to CP"
        onClick={foldAllTaskCMPs}
      >
        <MdMergeType className="cs-big-icon" />
      </span>
    </div>
  );

  return (
    <>
      {editableSchema ? (
        <div>
          <div className="action-bar">
            <Bar leftContent={leftContent} />
          </div>
          <div>
            <ul ref={listRef} className="js-model-list" position="relative">
              {renderedListItems}
            </ul>
          </div>
        </div>
      ) : (
        <p> Schema has not been generated.</p>
      )}
    </>
  );
};

export default PAD2Editor;
