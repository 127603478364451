import React from "react";
import { useModel } from "../../Model/ModelProvider";

const DeadPath = ({ id, deadPath }) => {
  const { getPAD2 } = useModel();
  const { editableSchema } = getPAD2();
  const { label: initLabel } = editableSchema.find(
    (s) => s.id === deadPath.at(-1)?.source
  );

  const path = deadPath.reduceRight(
    (prev, curr) => prev + " ➜ " + curr.targetNode,
    initLabel
  );

  return (
    <li id={id} className={`js-model-list-item sublist`}>
      <div className="content deadPath">
        <em style={{ color: "gray" }}>{path}</em>
      </div>
    </li>
  );
};

export default DeadPath;
