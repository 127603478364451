import React from "react";
import { useModel } from "./ModelProvider";
import ModelMenu from "./ModelMenu";
import Bar from "../../Bar";

//styles
import "../../css/Menus.css";

const ModelMetadata = ({ modelName }) => {
  const { isChanged } = useModel();

  const leftContent = (
    <div className="bar-left">
      <ul className="button-container">
        <li>
          <ModelMenu />
        </li>
      </ul>
    </div>
  );

  const centreContent = (
    <div className="centre-text">
      {modelName}
      {isChanged && <span> (You have unsaved changes!)</span>}
    </div>
  );

  const rightContent = null;

  return (
    <div className="top-bar model-bar">
      <Bar
        leftContent={leftContent}
        centreContent={centreContent}
        rightContent={rightContent}
      />
    </div>
  );
};

export default ModelMetadata;
