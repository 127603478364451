import React, { useContext } from "react";
import { RADManagerContext } from "./RADManagerLogic";
import RADHandler from "./Editor/RADHandler";
import RADSelector from "./RADSelector";
import FullScreenButton from "../UI/FullScreenButton";
import { DropProvider } from "./DropContext";
import RADShapes from "./Editor/RADShapes";
import Bar from "../../Bar";

const RADManagerUI = () => {
  const {
    currentRAD,
    fullScreen,
    toggleScreenMode,
    handleNewRAD,
    handleUpdateRAD,
    handleDeleteRAD,
    handleDeleteAllRADs,
  } = useContext(RADManagerContext);

  const leftContent = (
    <ul className="button-container">
      <li>
        <button
          className="csbutton"
          type="button"
          onClick={() => handleNewRAD()}
        >
          New
        </button>
      </li>
      <li>
        <RADSelector />
      </li>
      {currentRAD && (
        <li>
          <button
            className="csbutton"
            type="button"
            onClick={() => handleDeleteRAD(currentRAD)}
          >
            Delete
          </button>
        </li>
      )}
      <li>
        <button
          className="csbutton"
          type="button"
          onClick={() => handleDeleteAllRADs()}
        >
          Delete All (Temporary)
        </button>
      </li>
    </ul>
  );

  const centreContent = (
    <div className="centre-content">
      <div className="centre-text">
        Role Activity Diagram: {currentRAD?.label}
      </div>
    </div>
  );

  const rightContent = (
    <FullScreenButton
      fullScreen={fullScreen}
      toggleScreenMode={toggleScreenMode}
      element_id={"rad-manager"}
    />
  );

  return (
    <div id="rad-manager">
      <div className="rad-bar top-bar">
        <Bar
          leftContent={leftContent}
          centreContent={centreContent}
          rightContent={rightContent}
        />
      </div>
      <div id="rad-container" className="rad-container">
        <div className="window pane flex-container">
          <DropProvider>
            <RADShapes />
            <RADHandler currentRAD={currentRAD} onRadChange={handleUpdateRAD} />
          </DropProvider>
        </div>
      </div>
    </div>
  );
};

export default RADManagerUI;
