import { useContext, useState } from 'react'
import { SecondCutContext } from './SecondCutLogic'
import PAD2Diagram from './PAD2Diagram';
import PAD2Editor from './PAD2Editor';
import DeliveryChainEditor from './DeliveryChainEditor';
import ModalDialog from '../ModalDialog';
import Tabs from "../../UI/Tabs";


const SecondCutUI = ({ isActive }) => {
    const { isModalOpen, handleMerge, handleReplace } = useContext(SecondCutContext);

    const [activeEditor, setActiveEditor] = useState('processEditor');


    console.log("SecondCutUI isActive:", isActive);
    if (!isActive) return null;

    const tabData = [
        { key: 'processEditor', label: 'Process Edit', component: <PAD2Editor isActive={activeEditor === 'processEditor'} /> },
        { key: 'chainEditor', label: 'Delivery Chain Edit', component: <DeliveryChainEditor isActive={activeEditor === 'chainEditor'} /> },
    ];

    return (
        <div className='pad-container' style={{ position: "relative" }}>
            <div className='flex-pane'>
                {/* <HResizable minWidth={300}> */}
                <PAD2Diagram />
                <div className='narrow-pane'>
                    <Tabs tabs={tabData} activeTab={activeEditor} setActiveTab={setActiveEditor}>
                        {tabData.map((tab) => (
                            <div key={tab.key} hidden={activeEditor !== tab.key}>
                                {tab.component}
                            </div>
                        ))}
                    </Tabs>
                </div>
                {/* <RADPicker /> */}
            </div>
            <ModalDialog
                isOpen={isModalOpen}
                message="The second-cut has changed due to edits upstream. You have no choice, any changes here will be overwritten?"
                onConfirm={handleMerge}
                onCancel={handleReplace}
            />
        </div>
    )
}

export default SecondCutUI