import React, { useEffect, createContext, useContext, useReducer, useState } from "react";
import { modelReducer, initialModelState,
  getModel, getModelMeta,
  getEBEs, getEBEById, getGenerators, getGeneratorById,
  getUoWEBEs,
  getPAD1, getPAD2, 
  getRADs, getRADById,
  getRoles, getRoleById
 } from "./ModelLogic";

// Context
const ModelContext = createContext();

export const useModel = () => {
  const context = useContext(ModelContext);
  if (!context) {
    throw new Error("useModel must be used within a ModelProvider");
  }
  return context;
};

const ModelProvider = ({ children }) => {
  const [isChanged, setIsChanged] = useState(false);

  const initModel = () => {
    console.log("init function in ModelProvider");
    const savedData = localStorage.getItem("model");
    setIsChanged(false);
    return savedData ? JSON.parse(savedData) : { ...initialModelState };
  };

  const [model, dispatch] = useReducer(modelReducer, {}, initModel);

  const updateModel = (type, payload, sideEffectCallback) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({
          type: type,
          payload: payload,
        });

        if (sideEffectCallback) {
          sideEffectCallback();
        }

        resolve(); 
      } catch (error) {
        reject(error); 
      }
    });
  };

  const updatePADEditableSchema = (padKey, editSchema, modified = false) => {
    console.log("updatePADEditableSchema", padKey, editSchema, modified);
    dispatch({
      type: "UPDATE_PAD_EDITABLESCHEMA",
      payload: { padKey, editSchema: [...editSchema], modified }
    });
    setIsChanged(true);
  };

  const replacePADSchema = (padKey, newSchema) => {
    console.log("replacePADSchema", padKey, newSchema);
    dispatch({
      type: "REPLACE_PAD_SCHEMA",
      payload: { padKey, newSchema: [...newSchema], replaced: true }
    });
  };

  const replacePADEditableSchema = (padKey, force = false) => {
    console.log("replacePADEditableSchema", padKey, force);
    if (model && padKey && model[padKey] && (
      force === true ||
      JSON.stringify(model[padKey].editableSchema) !== JSON.stringify(model[padKey].schema))) {
      console.log("model provider Replacing PAD editable schema,", force ? "forced" : 'unforced,', padKey ? `for ${padKey}` : '');
      dispatch({
        type: "REPLACE_PAD_EDITABLESCHEMA",
        payload: { padKey: padKey, editSchema: [...model[padKey].schema] }
      });
    }
    else {
      console.log("model provider PAD editable schema unchanged,", padKey ? `for ${padKey}` : '');
      dispatch({
        type: "PAD_EDITABLESCHEMA_UNCHANGED",
        payload: { padKey: padKey }
      });
    }
  };

  useEffect(() => {

    const storedModel = localStorage.getItem("model");
    const currentModel = JSON.stringify(model);
    if (!storedModel || storedModel !== currentModel) {
      console.log("set local storage in ModelProvider");
      localStorage.setItem("model", currentModel);
    }
  }, [model]);


  const context = {
    getModel: () => getModel(model), 
    getModelMeta: () => getModelMeta(model),
    isChanged, setIsChanged, 
    updateModel,
    getEBEs: () => getEBEs(model),  
    getEBEById: (id) => getEBEById(model, id),
    getGenerators: () => getGenerators(model), 
    getGeneratorById: (id) => getGeneratorById(model, id),
    getUoWEBEs: () => getUoWEBEs(model),
    replacePADSchema ,
    replacePADEditableSchema,
    updatePADEditableSchema, 
    getPAD1: () => getPAD1(model), 
    getPAD2: () => getPAD2(model), 
    getRADs: () => getRADs(model), 
    getRADById: (id) => getRADById(model, id),
    getRoles: () => getRoles(model), 
    getRoleById: (id) => getRoleById(model, id)
  }
  return (
    <ModelContext.Provider value={context}>
      {children}
    </ModelContext.Provider>
  );
};

export { ModelContext, ModelProvider, };
