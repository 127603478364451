// EBEHandlerUI.js
import React from "react";
import EBEForm from "./EBEForm";
import EBEList from "./EBEList";

import "../../css/Editor.css";

const EBEEditor = () => {
    return (
        <div>
            <EBEForm />
            <EBEList />
        </div >
    );
};

export default EBEEditor;
