import React, { useRef } from "react";
import { BsHexagon, BsTrash } from "react-icons/bs";
import { useEBE } from "./EBEController";

const EBEListItem = ({ ebe, isHighlighted }) => {
  const { selectedEBE, setSelectedEBE, removeEBE, setPartialName } = useEBE();
  const selectedID = selectedEBE ? selectedEBE.id : null;
  const { id, name, isUOW } = ebe;
  const testRef = useRef(null);

  const uowstar = isUOW ? (
    <span>
      <BsHexagon
        className="cs-icon"
        title="UoW"
        onClick={(e) => e.stopPropagation()}
      />
    </span>
  ) : null;

  const handleSelectEBE = (ebe) => {
    selectedID === ebe.id ? setSelectedEBE(null) : setSelectedEBE(ebe);
    setPartialName("");
  };

  return (
    <li
      onClick={() => handleSelectEBE(ebe)}
      ref={testRef}
      className={`js-model-list-item ${selectedID === id ? "checked" : ""} ${
        isHighlighted ? "maybe" : ""
      }`}
    >
      <div className="content">
        {uowstar}
        <strong>{name}</strong>
      </div>
      <div className="right-button-container">
        <span
          className="remove actionButton"
          title="Delete this Generator!"
          onClick={(e) => {
            e.stopPropagation(); // Prevent click bubbling to li
            removeEBE(ebe);
          }}
        >
          <BsTrash className="cs-icon" />
        </span>
      </div>
    </li>
  );
};

export default EBEListItem;
