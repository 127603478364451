import React from 'react';
import { useModel } from './ModelProvider';
import ModelMetadata from './ModelMetadata';
import Pane from '../UI/Pane';
import CollapsibleComponent from "../UI/CollapsibleComponent";
import EBEHandler from '../EBEHandler/EBEHandler';
import GeneratorHandler from '../GeneratorHandler/GeneratorHandler';
import UOWDiagram from '../UOWDiagramHandler/UOWDiagram';
import PADHandler from '../PADHandler/PADHandler';


//styles
import '../../css/Window.css'
import '../../css/gvsvg.css' // Graphviz styles
import "../../css/Button.css"; // Button styles
import '../../css/Model.css' // Menu styles


const Model = () => {
    const { getModelMeta } = useModel();
    const modelName = getModelMeta().name;
    return (
        <div>
            <ModelMetadata modelName={modelName} />
            <div className='model-container'>
                <CollapsibleComponent label="Business Entities">
                    <div className="window">
                        <Pane title="EBE Editor">
                            <EBEHandler />
                        </Pane>
                        <Pane title="Relationship Editor">
                            <GeneratorHandler />
                        </Pane>
                        <Pane title="UOW Diagram">
                            <UOWDiagram />
                        </Pane>
                    </div>
                </CollapsibleComponent>
                <div className='window'>
                    <Pane>
                        <PADHandler />
                    </Pane>
                </div>
            </div>
        </div>
    );
};

export default Model;