import React from 'react';
import GeneratorForm from './GeneratorForm';
import GeneratorList from './GeneratorList';
import '../../css/Editor.css';

const GeneratorUI = () => {
    return (
        <div>
            <GeneratorForm />
            <GeneratorList />
        </div>
    );
};

export default GeneratorUI;
