import { useContext, useState, useEffect } from 'react';
import { useModel } from '../../Model/ModelProvider';
import { FirstCutContext } from './FirstCutLogic';

const PAD1DiagramLogic = ({ children }) => {
    const { getPAD1 } = useModel();
    const { graphId, setGraphId, restore } = useContext(FirstCutContext);
    const { editableSchema } = getPAD1();

    const [graph, setGraph] = useState('');

    useEffect(() => {
        const intro = `digraph G {
	    newrank=true;
        nodesep=0.75;    
	    node [shape=box, style=rounded, fontname="Arial"];
	    edge [dir=both, arrowhead=empty, arrowtail=odot, arrowsize=1.5, labeldistance=0.8, fontname="Arial", fontsize=12, labelfontsize=8]

        \n\n `;

        const outro = `\n}`;

        const pnodes = editableSchema
            .filter(s => s.class === 'node')
            .reduce((acc, p) => {
                if (p.uow !== 'External') {
                    // Group cp and cmp together
                    const pnode = `\t${p.id} [id=${p.id}; label="${p.label}";];\n`;
                    // Build the subgraph
                    return acc + `\n\tsubgraph cluster_${p.uow} { id=cluster_${p.uow}; rank = "same"; style="rounded";\n` + pnode + `}`;
                } else {
                    // Handle 'External' case
                    const ow = `\t${p.id} [id=${p.id}; shape=circle, color=none, label="☁", fontsize=64];\n`;
                    return acc + ow;
                }
            }, '');

        const pedges = editableSchema
            .filter(s => s.class !== 'node')
            .reduce((acc, edge) => {
                if (edge.class !== 'external_requests') {
                    const pedge = `\t${edge.source} -> ${edge.target} [id="${edge.id}"; label="${edge.label}", class="${edge.class}", taillabel = ${edge.tail}];\n`;
                    return acc + pedge;
                } else {
                    const request = `\t${edge.source} -> ${edge.target} [id=${edge.id}; label="${edge.label}", class="${edge.class}",tailclip=false, arrowtail=dot ];\n`;
                    return acc + request;
                }
            }, '');

        const digraph = intro + pnodes + pedges + outro;
        setGraph(digraph);
        return () => {
            setGraph('');
        }
    }, [editableSchema]);

    return children({ graph, graphId, setGraphId, restore });
};

export default PAD1DiagramLogic;
