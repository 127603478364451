// PAD2DiagramUI.js

import React, { Suspense, useRef } from "react";
import { usePAD2 } from "./PAD2DiagramLogic";
import BigPicture from "../../DiagramHandler/BigPicture";
import Bar from "../../../Bar";
import useContextMenu from "../../hooks/useContextMenu";
import ContextMenu from "../../UI/ContextMenu";

import { BsHexagon } from "react-icons/bs";
//import { FiPlus } from 'react-icons/fi';
import { MdMergeType } from "react-icons/md";
//import { FaPlus } from 'react-icons/fa';

const GraphvizDiagramInteractive = React.lazy(() =>
  import("../../DiagramHandler/GraphvizDiagramInteractive")
);
const GraphvizDiagram = React.lazy(() =>
  import("../../DiagramHandler/GraphvizDiagram")
);

const PAD2DiagramUI = () => {
  const {
    graph,
    graphId,
    setGraphId,
    restore,
    selectedNodeType,
    ghost,
    deliveriesOnly,
    handleFoldCMPClick,
    foldAllTaskCMPs,
    toggleGhost,
    toggleDeliveriesOnly,
  } = usePAD2();

  const contextRef = useRef(null);
  const inset = { width: 250, height: 350 };
  const [position, isShowContext] = useContextMenu(contextRef, inset);

  const caption = "Second-cut process architecture";
  const centreContent = <figcaption>{caption}</figcaption>;

  const vizRef = useRef(null);

  const menuItems = [
    {
      label: "Fold all task CMPs to CP",
      icon: MdMergeType,
      onClick: foldAllTaskCMPs,
    },
    ...(selectedNodeType === "CMP"
      ? [
          {
            label: "Fold selected CMP to a CP",
            icon: MdMergeType,
            onClick: () => handleFoldCMPClick(graphId),
          },
        ]
      : []),
    {
      label: "Add designed entities",
      icon: BsHexagon,
      onClick: () => console.log("Add designed entities clicked"), // Placeholder for action
    },
  ];

  const graphActions = (
    <>
      <button
        type="button"
        className="csbutton"
        onClick={toggleGhost}
        style={{ display: "block" }}
      >
        👻 {ghost ? " on" : " off"}
      </button>
      <button
        type="button"
        className="csbutton"
        onClick={toggleDeliveriesOnly}
        style={{ display: "block" }}
      >
        🚚 {deliveriesOnly ? " on" : " off"}
      </button>
      <button
        type="button"
        className="csbutton"
        onClick={restore}
        style={{ display: "block" }}
      >
        ⏪ Start again
      </button>
    </>
  );

  return (
    <>
      {graph ? (
        <div className="flex-column expanding-pane">
          <Suspense fallback={<div>Loading diagram...</div>}>
            <BigPicture
              Component={GraphvizDiagram}
              componentProps={{ data: graph, title: caption }}
            />
            <GraphvizDiagramInteractive
              ref={vizRef}
              data={graph}
              graphId={graphId}
              setGraphId={setGraphId}
              contextRef={contextRef}
            >
              {graphActions}
              <ContextMenu
                position={position}
                isShowContext={isShowContext}
                menuItems={menuItems}
              />
            </GraphvizDiagramInteractive>
          </Suspense>
          <Bar centreContent={centreContent} />
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>Nothing to see here yet!</p>
      )}
    </>
  );
};

export default PAD2DiagramUI;
