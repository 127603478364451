import SecondCutLogic from './SecondCutLogic';
import SecondCutUI from './SecondCutUI';

const SecondCut = ({ isActive }) => {
    return (
        <SecondCutLogic>
            <SecondCutUI isActive={isActive} />
        </SecondCutLogic>
    );
};

export default SecondCut;