import React, { useContext, useEffect, useRef } from "react";
import { useModel } from "../../Model/ModelProvider";
import { SecondCutContext } from "./SecondCutLogic";
import { FaEllipsisV } from "react-icons/fa";

const PAD2ListItem = ({ process: { id, label, type } }) => {
  const { getPAD2 } = useModel();
  const { editableSchema } = getPAD2();
  const { graphId, setGraphId } = useContext(SecondCutContext);

  const renderedSublistItems = editableSchema
    .filter((s) => s.class !== "node" && s.source === id && s.status === "live")
    .map((r) => {
      return (
        <PAD2SublistItem
          key={r.source + r.target + r.class}
          rid={r.source + "_" + r.target + "_" + r.class}
          {...r}
        />
      );
    });

  const selectedItemRef = useRef(null); // Ref for the selected item

  useEffect(() => {
    if (graphId === id && selectedItemRef.current) {
      selectedItemRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [graphId, id]);

  return (
    <div key={id}>
      <li
        key={"li" + id}
        id={id}
        onClick={() => {
          graphId === id ? setGraphId("") : setGraphId(id);
        }}
        className={`js-model-list-item ${graphId === id ? "checked" : ""} `}
        ref={id === graphId ? selectedItemRef : null}
      >
        <div className="content">
          <strong> {label} </strong>
          <span>[{type}]</span>
        </div>
        {/* Action Buttons */}
        <div className="right-button-container">
          <span
            className="edit actionButton"
            title="Hide this"
            style={{
              display: graphId === id ? "inline" : "none",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent click bubbling to li
              // do something
            }}
          >
            <FaEllipsisV className="cs-icon" />
          </span>
        </div>
      </li>
      <ul className="js-model-list" key={"ul_" + id} id={"ul_" + id}>
        {renderedSublistItems}
      </ul>
    </div>
  );
};

const PAD2SublistItem = ({ rid, label }) => {
  const { graphId, setGraphId } = useContext(SecondCutContext);
  const { getPAD2 } = useModel();
  const { editableSchema } = getPAD2();

  const process = editableSchema.find(
    (s) => s.class === "node" && s.id === rid
  ) ?? { label: "" };
  const { label: processLabel } = process;

  const sublistRef = useRef(null);
  useEffect(() => {
    if (graphId === rid && sublistRef.current) {
      sublistRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [graphId, rid]);

  return (
    <li
      ref={rid === graphId ? sublistRef : null}
      id={rid}
      onClick={() => {
        graphId === rid ? setGraphId("") : setGraphId(rid);
      }}
      className={`js-model-list-item sublist ${
        graphId === rid ? "checked" : ""
      }`}
    >
      <div className="content">
        <em>{label}</em>
        <strong> {processLabel} </strong>
      </div>
      {/* Action Buttons */}
      <div className="right-button-container">
        <span
          className="edit actionButton"
          title="Hide this"
          style={{
            display: graphId === rid ? "inline" : "none",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent click bubbling to li
            // do something
          }}
        >
          <FaEllipsisV className="cs-icon" />
        </span>
      </div>
    </li>
  );
};

export default PAD2ListItem;
