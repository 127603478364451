// Providers.js

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ModelProvider } from './features/Model/ModelProvider';
import { ThemeProvider } from './features/UI/ThemeContext';
import { FullScreenProvider } from './features/UI/FullScreenProvider';

const Providers = ({ children }) => {
    return (
        <ThemeProvider>
            <FullScreenProvider>
                <BrowserRouter>
                    <ModelProvider>
                        {children}
                    </ModelProvider>
                </BrowserRouter>
            </FullScreenProvider>
        </ThemeProvider>
    );
};

export default Providers;