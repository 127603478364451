import React from "react";
import { useEBE } from "./EBEController";
import EBEListItem from "./EBEListItem";


const EBEList = () => {
  const { ebeList, partialName } = useEBE();

  const renderedListItems = ebeList.map((ebe) => {
    return <EBEListItem key={ebe.id} ebe={ebe} isHighlighted={partialName ? ebe.name.startsWith(partialName) : false } />;
  });
 
  return (
    <div>
    <div className="liheader">
      <h3>EBE List </h3>
      </div>
      <ul className="js-model-list">{renderedListItems}</ul>
    </div>
  );
};

export default EBEList;
