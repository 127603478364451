// Tabs.js

const Tabs = ({ tabs, activeTab, setActiveTab, children, tabOptions }) => (
    <div id={activeTab}>
        <div className="tab">
            <div className="tab-header">
                <div className="tab-buttons">
                    {tabs.map((tab) => (
                        <button
                            key={tab.key}
                            className={tab.key === activeTab ? 'active' : ''}
                            onClick={() => setActiveTab(tab.key)}
                        >
                            {tab.label}
                        </button>
                    ))}
                </div>
                <div className="tab-options">
                    {tabOptions && tabOptions.component}
                </div>
            </div>
        </div>
        <div className="tab-content">{children}</div>
    </div>
);

export default Tabs;