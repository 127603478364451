import { createContext, useContext, useState, useEffect } from 'react'

import { useModel } from '../../Model/ModelProvider'
import { SecondCutContext } from './SecondCutLogic'

export const PAD2Context = createContext();

export const usePAD2 = () => {
    const context = useContext(PAD2Context);
    if (!context) {
        throw new Error('usePAD2 must be used within a PAD2Provider');
    }
    return context;
};

const PAD2DiagramLogic = ({ children }) => {
    const { getPAD2, getGeneratorById, updatePADEditableSchema } = useModel();
    const { graphId, setGraphId, restore, selectedNodeType } = useContext(SecondCutContext);
    const { editableSchema, modified } = getPAD2();

    const [ghost, setGhost] = useState(false)
    const [deliveriesOnly, setDeliveriesOnly] = useState(false)
    const toggleGhost = () => {
        setGhost(!ghost)
    }
    const toggleDeliveriesOnly = () => {
        setDeliveriesOnly(!deliveriesOnly)
    }

    const [graph, setGraph] = useState('');

    const foldAllTaskCMPs = () => {

        const negotiators = editableSchema.filter(s => s.class === 'negotiates' && s.status === 'live');
        const taskCMPs = negotiators.flatMap(s => {
            const generator = getGeneratorById(s.genId);
            return generator?.processType === 'Task Force' ? [s.source] : [];
        });

        let updatedSchema = [...editableSchema];
        taskCMPs.forEach(source => {
            updatedSchema = foldCMP(source, updatedSchema);
        });

        updatePADEditableSchema('pad2', updatedSchema, true);
    }

    const handleFoldCMPClick = (id) => {
        console.log('handleFoldCMPClick', id)
        const updatedSchema = foldCMP(id, editableSchema);

        // Update the model
        updatePADEditableSchema('pad2', updatedSchema, true);
    }

    const foldCMP = (id, schema) => {
        if (!schema.find(s => s.id === id && s.type === 'CMP')) return schema;

        const edges = schema.filter(s => s.source === id);

        // Get the target of the 'negotiates' edge and the CP of the CMP
        const negotiatesTarget = edges.find(s => s.class === 'negotiates')?.target;
        const startsTarget = edges.find(s => s.class === 'starts')?.target;

        // Update the status of the node and its edges
        const updatedSchema = schema.map(s => {
            if (s.id === id || s.source === id || s.target === id) {
                return { ...s, status: 'dead' };
            }
            return s;
        });

        // Add a new start edge if it does not exist
        const newEdgeId = `${negotiatesTarget}_${startsTarget}_starts`;
        if (!updatedSchema.some(s => s.id === newEdgeId)) {
            updatedSchema.push({
                source: negotiatesTarget,
                target: startsTarget,
                class: 'starts',
                id: newEdgeId,
                status: 'live',
                label: 'start',
                tail: '"A"',
                actionType: 'fold'
            });
        }
        return updatedSchema;
    }

    useEffect(() => {
        if (!editableSchema) return;
        const intro = `digraph G {
        newrank=true;
        nodesep=0.75;    
        node [shape=box, style=rounded, fontname="Arial"];
        edge [dir=both, arrowhead=empty, arrowtail=odot, arrowsize=1.5, labeldistance=0.8, fontname="Arial", fontsize=12]
        
        \n\n `;
        const outro = `\n}`;

        const pnodes = editableSchema
            .filter(s => s.class === 'node' && (ghost || (!ghost && s.status === 'live')))
            .reduce((acc, node) => {
                if (node.uow !== 'External') {
                    const nodeClass = (node.status === 'dead') ? ' disabled' : '';
                    const pnode = `\t${node.id} [id="${node.id}"; label="${node.label}", class="${nodeClass}"];\n`;
                    return acc + `\n\tsubgraph cluster_${node.uow} { id=cluster_${node.uow}; rank = "same"; style="filled, rounded";\n` + pnode + `}`;
                } else {
                    // Handle 'External' case
                    const ow = `\t${node.id} [id=${node.id}; shape=circle, color=none, label="☁", fontsize=64];\n`;
                    return acc + ow;
                }
            }, '');

        const pedges = editableSchema
            .filter(s => s.class !== 'node' && (ghost || (!ghost && s.status === 'live'))
                && (!deliveriesOnly || (deliveriesOnly && s.class === 'delivers')))
            .reduce((acc, edge) => {
                if (edge.class !== 'external_requests') {
                    const edgeClass = edge.class.concat((edge.status === 'dead') ? ' disabled' : '');
                    const pedge = `\t${edge.source} -> ${edge.target} [id="${edge.id}"; label="${edge.label}", class="${edgeClass}", taillabel = ${edge.tail}];\n`;
                    return acc + pedge;
                } else {
                    const pedge = `\t${edge.source} -> ${edge.target} [id=${edge.id}; label="${edge.label}", class="${edge.class}",tailclip=false, arrowtail=dot ];\n`;
                    return acc + pedge;
                }
            }, '');

        const digraph = intro + pnodes + pedges + outro;
        setGraph(digraph);
        return () => {
            console.log("PAD2Diagram: clear graph");
            setGraph('');
        };
    }, [editableSchema, modified, ghost, deliveriesOnly]);

    return (
        <PAD2Context.Provider
            value={{
                graph,
                graphId,
                setGraphId,
                restore,
                selectedNodeType,
                ghost,
                deliveriesOnly,
                handleFoldCMPClick,
                foldAllTaskCMPs,
                toggleGhost,
                toggleDeliveriesOnly
            }}
        >
            {children}
        </PAD2Context.Provider>
    );
}

export default PAD2DiagramLogic

