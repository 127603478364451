import { useState, useRef } from "react";
import { useModel } from "./ModelProvider";
import { useOutsideCallback } from "../hooks/useOutsideCallback";

//styles
import "../../css/Popup.css";

const ModelForm = ({ onClose }) => {
  const { getModelMeta, updateModel, setIsChanged } = useModel();

  const popupRef = useRef(null);

  const editModelMeta = getModelMeta();
  const [inputs, setInputs] = useState(editModelMeta); // model.modelMeta is the initial state
  const [isEdited, setIsEdited] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setIsEdited(true);
  };

  const handleKeyDown = (event) => {
    const trimmedText = event.target.value.trim();
    if ((event.key === "Enter" || event.key === "Tab") && trimmedText) {
      event.preventDefault();
      // Step to the next field.
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
    } else if (event.key === "Escape") {
      handleCancel();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateModel(
      "UPDATE_MODEL_PART",
      { partKey: "modelMeta", value: { ...inputs } },
      () => {
        setIsChanged(true);
      }
    );
    setIsEdited(false);
    handleClose();
  };

  const handleCancel = () => {
    // revert the form
    setInputs(editModelMeta);
    setInputs(false);
    handleClose();
  };

  // useOutsideCallback should call onClose when a click outside is detected
  useOutsideCallback(popupRef, onClose);

  return (
    <nav className="menu-item">
      <div className="popup-box">
        <div className="box" ref={popupRef} onKeyDown={handleKeyDown}>
          <h2>Model: {inputs.name}</h2>
          <br></br>
          <form>
            <label>
              Name:
              <input
                type="text"
                required
                name="name"
                value={inputs.name}
                placeholder="Enter your model name"
                onKeyDown={handleKeyDown}
                pattern="[a-zA-Z0-9_ \-]+"
                onChange={handleChange}
                autoFocus
              />
            </label>
            <label>
              Purpose:
              <textarea
                name="purpose"
                value={inputs.purpose}
                placeholder="Explain the purpose of this model"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
              />
            </label>
            <br />
            <button
              name="submitBtn"
              type="button"
              className="csbutton"
              disabled={!inputs.name || !isEdited}
              onClick={handleSubmit}
            >
              Update
            </button>
            <button
              name="clearBtn"
              type="button"
              className="csbutton"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </form>
          <footer className="footer">
            <div className="centre-content">
              <figcaption>{`${
                isEdited ? "You have " : "No"
              } pending changes.`}</figcaption>
              <figcaption>
                Use Cancel button or Esc key to cancel any changes you make.
              </figcaption>
              <figcaption>
                Letters, digits, underscore(_), hyphen and spaces only in the
                Name field.
              </figcaption>
            </div>
          </footer>
        </div>
      </div>
    </nav>
  );
};

export default ModelForm;
