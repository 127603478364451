import React, { useRef } from "react";
import ThemePaletteSelector from "../UI/ThemePaletteSelector";
import { Outlet, NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Bar from "../../Bar";
import { useOutsideEvent } from "../hooks/useOutsideEvent";

//styles
import "../../css/Navbar.css";
import "../../css/Menus.css";

const NavBar = () => {
  const leftMenuRef = useRef(null);
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useOutsideEvent(
    leftMenuRef,
    false
  );

  const rightMenuRef = useRef(null);
  const [isRightMenuOpen, setIsRightMenuOpen] = useOutsideEvent(
    rightMenuRef,
    false
  );

  const toggleLeftMenu = () => {
    setIsLeftMenuOpen(!isLeftMenuOpen);
  };

  const toggleRightMenu = () => {
    setIsRightMenuOpen(!isRightMenuOpen);
  };

  return (
    <nav className="navbar">
      <Bar
        leftContent={
          <div className="menu-item" ref={leftMenuRef}>
            <div className="nav-toggle" onClick={toggleLeftMenu}>
              <FaBars className="bar-icon" />
            </div>
            <ul
              className={`nav-menu ${
                isLeftMenuOpen ? "dropdown show" : ""
              }  left-menu`}
            >
              <li className="menu-item">
                <Link to="/">Home</Link>
              </li>
              <li className="menu-item">
                <Link to="/rad">RAD Tool</Link>
              </li>
              <li className="menu-item">
                <Link to="/about">About</Link>
              </li>
              <li className="menu-item">
                <Link to="/examples">Examples</Link>
              </li>
            </ul>
          </div>
        }
        centreContent={
          <h1 className="centre-text">RIVA Business Process Management</h1>
        }
        rightContent={
          <div className="menu-item" ref={rightMenuRef}>
            <div className="nav-toggle" onClick={toggleRightMenu}>
              <FaBars className="bar-icon" />
            </div>
            <ul
              className={`nav-menu  ${
                isRightMenuOpen ? "dropdown show" : ""
              } right-menu`}
            >
              <li>
                <ThemePaletteSelector />
              </li>
              <li className="menu-item">
                <Link to="/language">Language</Link>
              </li>
              <li className="menu-item">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="menu-item">
                <Link to="/help">Help</Link>
              </li>
            </ul>
          </div>
        }
      />
      <Outlet />
    </nav>
  );
};

export default NavBar;
