// CollapsibleComponent.js
import React, { useState } from "react";

const CollapsibleComponent = ({ label, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div>
      <button
        className={isCollapsed ? "collapsible closed" : "collapsible open"}
        type="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {label}
      </button>
      <div style={{ display: isCollapsed ? "none" : "block" }}>{children}</div>
    </div>
  );
};

export default CollapsibleComponent;
