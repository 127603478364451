// Bar.js

import React from "react";

const Bar = ({ leftContent, centreContent, rightContent }) => {
  return (
    <div className="bar">
      <div className="bar-left">{leftContent}</div>
      <div className="centre-content">{centreContent}</div>
      <div className="bar-right">{rightContent}</div>
    </div>
  );
};

export default Bar;
