import React, { Suspense } from "react";
import BigPicture from "../../DiagramHandler/BigPicture";
import Bar from "../../../Bar";

const GraphvizDiagramInteractive = React.lazy(() =>
  import("../../DiagramHandler/GraphvizDiagramInteractive")
);
const GraphvizDiagram = React.lazy(() =>
  import("../../DiagramHandler/GraphvizDiagram")
);

const caption = "First-cut process architecture";
const centreContent = <figcaption>{caption}</figcaption>;
const PAD1DiagramUI = ({ graph, graphId, setGraphId, restore }) => {
  return (
    <>
      {graph ? (
        <div className="flex-column expanding-pane">
          <Suspense fallback={<div>Loading diagram...</div>}>
            <BigPicture
              Component={GraphvizDiagram}
              componentProps={{ data: graph, title: caption }}
            />
            <GraphvizDiagramInteractive
              data={graph}
              graphId={graphId}
              setGraphId={setGraphId}
            >
              <button
                type="button"
                className="csbutton"
                onClick={restore}
                style={{ display: "block" }}
              >
                ⏪ Start again
              </button>
            </GraphvizDiagramInteractive>
          </Suspense>
          <Bar centreContent={centreContent} />
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>Nothing to see here yet!</p>
      )}
    </>
  );
};

export default PAD1DiagramUI;
