import React, { useState, useEffect } from "react";
import { useModel } from "../../Model/ModelProvider";
import DeadPath from "./DeadPaths";
import { getShortCircuitList, getDeadPaths } from "./utils";
import { FaUndo } from "react-icons/fa";

const ShortCircuitList = () => {
  const { getPAD2, updatePADEditableSchema } = useModel();
  const { editableSchema } = getPAD2();
  const [shortCircuits, setShortCircuits] = useState([]);
  const [selectedSC, setSelectedSC] = useState("");

  useEffect(() => {
    if (!editableSchema) return;
    console.log("PAD2SecondCut: delivery chains and short circuits effect");
    const newShortCircuits = getShortCircuitList(editableSchema);
    console.log("newShortCircuits", newShortCircuits);
    setShortCircuits(newShortCircuits);
  }, [editableSchema]);

  const removeShortCircuit = (id) => {
    if (!id) return;
    console.log("removing short-circuit", id);
    const revive = editableSchema
      .filter((m) => m.replacedBy === id)
      .map((s) => {
        return { ...s, status: "live", replacedBy: "" };
      });
    const newSchema = editableSchema
      .filter((s) => s.id !== id && s.replacedBy !== id)
      .concat(revive);
    updatePADEditableSchema("pad2", newSchema, true);
    return null;
  };

  const handleClick = (id) => {
    console.log("showchain: ", id, selectedSC);
    selectedSC === id ? setSelectedSC("") : setSelectedSC(id);
  };

  const handleShortCircuitRemoval = (id) => {
    removeShortCircuit(id);
  };

  const renderedShortCircuits =
    shortCircuits &&
    shortCircuits.map((sc) => {
      return (
        <ShortCircuitListItem
          key={sc.id}
          sc={sc}
          selectedSC={selectedSC}
          handleClick={handleClick}
          handleShortCircuitRemoval={handleShortCircuitRemoval}
        />
      );
    });

  return (
    <div className="liheader">
      <h3>Short-circuits taken</h3>
      {renderedShortCircuits && renderedShortCircuits.length > 0 ? (
        <ul className="js-model-list">{renderedShortCircuits}</ul>
      ) : (
        <>
          <p> There are currently no short-circuits.</p>
          <hr></hr>
        </>
      )}
    </div>
  );
};

export default ShortCircuitList;

const ShortCircuitListItem = ({
  sc,
  selectedSC,
  handleClick,
  handleShortCircuitRemoval,
}) => {
  const { getPAD2 } = useModel();
  const { editableSchema } = getPAD2();
  const { id, sourceNodeLabel, targetNodeLabel } = sc;
  const deadPaths = getDeadPaths(id, editableSchema);

  const handleMouseOver = () => {
    //console.log('mouse over chain')
  };
  const showDeadPaths = deadPaths.map((d) => {
    return <DeadPath key={d.id} id={d.id} deadPath={d.deliveryChain} />;
  });

  return (
    <>
      <li
        id={id}
        onClick={() => {
          handleClick(id);
        }}
        onMouseOver={() => {
          handleMouseOver();
        }}
        className={`js-model-list-item ${selectedSC === id ? "checked" : ""}`}
      >
        <div className="content">
          <strong>
            {sourceNodeLabel} ➜ {targetNodeLabel}
          </strong>
          <br></br>
        </div>
        <div className="right-button-container">
          <span
            className="edit actionButton"
            title="Remove short-circuit from this delivery chain."
            style={{
              display: id === selectedSC ? "inline" : "none",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent click bubbling to li
              handleShortCircuitRemoval(id);
            }}
          >
            <FaUndo className="cs-icon" />
          </span>
        </div>
      </li>
      <ul className="js-model-list">{showDeadPaths}</ul>
    </>
  );
};
