import PAD2DiagramLogic from "./PAD2DiagramLogic";
import PAD2DiagramUI from "./PAD2DiagramUI";

const PAD2Diagram = () => {
  return (
    <PAD2DiagramLogic>
      <PAD2DiagramUI />
    </PAD2DiagramLogic>
  );
};

export default PAD2Diagram;
