import { useState, useEffect, useCallback, useRef } from "react";

const useContextMenu = (el, inset = { width: 0, height: 0 }) => {
  //inset is used to stop menu going off screen.
  const [isActive, setIsActive] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const pressTimer = useRef(null);

  const handleContextMenu = useCallback(
    (event) => {
      if (el.current !== null && el.current.contains(event.target)) {
        console.log("context menu", event.target);
        event.preventDefault();
        event.stopPropagation();
        let eventLeft = event.pageX || event.touches[0].pageX;
        let eventTop = event.pageY || event.touches[0].pageY;
        let elBottom = el.current.offsetTop + el.current.offsetHeight;
        let elRight = el.current.offsetLeft + el.current.offsetWidth;
        let posX =
          eventLeft < elRight - inset.width
            ? eventLeft
            : elRight - inset.width - 10;
        let posY =
          eventTop < elBottom - inset.height
            ? eventTop
            : elBottom - inset.height - 10;
        setPos({ x: posX, y: posY });
        console.log("context pos", posX, posY);
        setIsActive(true);
      }
    },
    [setIsActive, setPos, el, inset]
  );

  const handler = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      isActive && setIsActive(false);
    },
    [isActive]
  );

  const handleTouchStart = useCallback(
    (event) => {
      // Prevent default behavior immediately
      event.preventDefault();
      event.stopPropagation();

      console.log("touch start", event.target);

      pressTimer.current = setTimeout(() => {
        const touch = event.touches[0];
        const simulatedEvent = {
          type: "press",
          clientX: touch.clientX,
          clientY: touch.clientY,
          target: event.target,
          preventDefault: () => {},
          stopPropagation: () => {},
        };
        handleContextMenu(simulatedEvent);
      }, 600); // Long press duration in milliseconds
    },
    [handleContextMenu]
  );

  const handleTouchEnd = useCallback(() => {
    clearTimeout(pressTimer.current);
  }, []);

  useEffect(() => {
    const elCurrentCopy = el.current;
    document.addEventListener("click", handler);
    if (elCurrentCopy) {
      el.current.addEventListener("touchstart", handleTouchStart);
      el.current.addEventListener("touchend", handleTouchEnd);
      el.current.addEventListener("contextmenu", handleContextMenu);
    }
    return () => {
      // Cleanup the event listener
      document.removeEventListener("click", handler);
      if (elCurrentCopy) {
        elCurrentCopy.removeEventListener("contextmenu", handleContextMenu);
        elCurrentCopy.removeEventListener("touchstart", handleTouchStart);
        elCurrentCopy.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [handler, handleTouchStart, handleTouchEnd, handleContextMenu, el]);

  return [pos, isActive];
};

export default useContextMenu;
